import React,{useState, useEffect} from 'react';
import axios from "axios";
import { useQuery } from "react-query";
import bgbreadcrumbimg from '../images/slider.webp';
import {Accordion} from 'flowbite-react';
import {Helmet} from "react-helmet";
import { NavLink } from "react-router-dom";
function Faq() {
    const user_token = localStorage.getItem("coconuttoken");
    const headers = {
        'Content-Type': 'application/json'
    };
    const BASE_URL = "https://application.coconuttickets.com";
    const [faqs, setFaq] = useState([]);
    const [posts, setPosts] = useState([]);
    const openaccordion=(e)=>{
            if (e.target === e.currentTarget) {
                e.currentTarget.nextElementSibling.toggleAttribute("hidden"); 
            }
    }
    const openaccordion_p=(e)=>{
        if (e.target === e.currentTarget) {
            e.currentTarget.parentElement.parentElement.parentElement.nextElementSibling.toggleAttribute("hidden"); 
        }
    }
    const fetchfaqs= async () => {
        const response = await axios.get( `${BASE_URL}/api/public_faqs?page=faq`, { headers });
        return response.data;
    };
    const ShowhomeFaq = () => {
        const map1 = faqs.map((row, i) => {
            if(row.section == 'event_organizer'){
            return (
                <>
                <Accordion.Panel>
                    <Accordion.Title className='text-brand-600' onClick={(e) => {
                                openaccordion(e);
                                }} >
                            <span className="text-brand-600 text-xl font-semibold" id="accordion-collapse-heading-1"> <span  onClick={(e) => {
                                openaccordion_p(e);
                                }}>{row.title}</span></span> 
                    </Accordion.Title>
                    <Accordion.Content>
                        <p className="text-slate-600 text-lg">{row.content}</p>
                        </Accordion.Content>
                    </Accordion.Panel> 
                </>                
                );
            }
        })
        return map1
    }
    const ShowhomeFaq1 = () => {
        
        const map1 = faqs.map((row, i) => {
            if(row.section == 'ticket_buyer'){
            return (
                <>
                    <Accordion.Panel>
                        <Accordion.Title onClick={(e) => {
                                    openaccordion(e);
                                    }} >
                            <span className="text-brand-600 text-xl font-semibold" id="accordion-collapse-heading-1"> <span onClick={(e) => {
                                    openaccordion_p(e);
                                    }}>{row.title}</span></span> 
                        </Accordion.Title>
                        <Accordion.Content>
                            <p className="text-slate-600 text-lg">{row.content}</p>
                        </Accordion.Content>
                    </Accordion.Panel> 
                </>                
                );
            }
        })
        return map1
    }
    const fetchContents = async () => {
        const response = await axios.get( `${BASE_URL}/api/public_contents?page=about`, { headers });
        return response.data;
    };
    const ShowAboutContents = () => {
        const { isLoading, isSuccess, data, error }= useQuery('fetchContents',fetchContents)
        useEffect(() => {
            if(isLoading === false && data){
                setPosts(data);
            }
        }, [ data]) 
    }
    const MetahomeContents = () => {
        return (
            <>
              {posts.map(homemetafetch => (
              homemetafetch.section == 'meta_data' ?
                    (<Helmet><title>{homemetafetch.title}</title><meta name="description" content={homemetafetch.content} /> </Helmet> )
               :(<Helmet><title>Coconuttickets</title></Helmet> )
              ))}
            </>
        )
       
    }
    
    const ShowfaqContents = () => {
        const { isLoading, isSuccess, data, error }= useQuery('fetchContents',fetchfaqs)
        useEffect(() => {
            if(isLoading === false && data){
                setFaq(data);
            }
        }, [isLoading, data])
        if (isLoading) return <div className="blur-2xlv h-screen w-screen">
                                    <div className="animate-pulse flex space-x-4">
                                        <div className="container mx-auto mt-80">
                                            <div className="grid">
                                                <div className="space-y-3">
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                    <div className="grid grid-cols-3 gap-4">
                                                        <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                                                        <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                                                    </div>
                                                    <div className="h-2 bg-slate-200 rounded"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                             </div>
        if (isSuccess) return  <Faqfullpage />
    }
  

    const Faqfullpage = () => {
        return (
            <>
            
            
            
            <section className="innerheader relative table w-full pt-16 breadcrumb-bg pb-36" style={{backgroundImage: `url(${bgbreadcrumbimg})`}}>
                <div className="absolute inset-0 opacity-75"></div>
                <div className="md:container md:mx-auto max-sm:px-4">
                    <div className="grid grid-cols-1 text-center mt-10">
                    <h1 className="mt-2 mb-4 text-4xl lg:text-5xl md:leading-normal leading-normal font-medium text-white">Answers to your questions </h1>
                    
                    </div>
                </div>
                <div className="absolute text-center z-10 right-0 left-0 mx-3">
                    <nav className="" aria-label="Breadcrumb">
                        <ol className="inline-flex items-center space-x-1 md:space-x-3">
                            <li className="inline-flex items-center">
                                <NavLink to={'/'} className="inline-flex items-center text-sm font-medium text-gray-200 hover:text-brand-600 dark:text-gray-400 dark:hover:text-white">
                                    <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <div className="flex items-center">
                                    <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                    <NavLink to={'/'} className="ml-1 text-sm font-medium text-gray-200 hover:text-brand-600 md:ml-2 dark:text-gray-400 dark:hover:text-white">FAQ's</NavLink>
                                </div>
                            </li>
                        </ol>
                    </nav>

                </div>
            </section>
            <div className="relative">
                <div className="shape absolute right-0 left-0 overflow-hidden z-1 text-gray-50 dark:text-slate-800 z-50 bottom-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="15 17 1440 50" style={{transition: '0.3s'}}>
                        <defs>
                            <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
                                <stop stopColor="rgba(0, 0, 0, 1)" offset="0%"/><stop stopColor="rgba(0, 0, 0, 1)" offset="100%"/>
                            </linearGradient>
                        </defs>
                        <path fill="#FFF" fillOpacity="1" d="M0,30L21.8,28.3C43.6,27,87,23,131,20C174.5,17,218,13,262,21.7C305.5,30,349,50,393,48.3C436.4,47,480,23,524,18.3C567.3,13,611,27,655,33.3C698.2,40,742,40,785,41.7C829.1,43,873,47,916,51.7C960,57,1004,63,1047,63.3C1090.9,63,1135,57,1178,46.7C1221.8,37,1265,23,1309,23.3C1352.7,23,1396,37,1440,45C1483.6,53,1527,57,1571,51.7C1614.5,47,1658,33,1702,36.7C1745.5,40,1789,60,1833,68.3C1876.4,77,1920,73,1964,75C2007.3,77,2051,83,2095,85C2138.2,87,2182,83,2225,76.7C2269.1,70,2313,60,2356,50C2400,40,2444,30,2487,28.3C2530.9,27,2575,33,2618,38.3C2661.8,43,2705,47,2749,48.3C2792.7,50,2836,50,2880,46.7C2923.6,43,2967,37,3011,38.3C3054.5,40,3098,50,3120,55L3141.8,60L3141.8,100L3120,100C3098.2,100,3055,100,3011,100C2967.3,100,2924,100,2880,100C2836.4,100,2793,100,2749,100C2705.5,100,2662,100,2618,100C2574.5,100,2531,100,2487,100C2443.6,100,2400,100,2356,100C2312.7,100,2269,100,2225,100C2181.8,100,2138,100,2095,100C2050.9,100,2007,100,1964,100C1920,100,1876,100,1833,100C1789.1,100,1745,100,1702,100C1658.2,100,1615,100,1571,100C1527.3,100,1484,100,1440,100C1396.4,100,1353,100,1309,100C1265.5,100,1222,100,1178,100C1134.5,100,1091,100,1047,100C1003.6,100,960,100,916,100C872.7,100,829,100,785,100C741.8,100,698,100,655,100C610.9,100,567,100,524,100C480,100,436,100,393,100C349.1,100,305,100,262,100C218.2,100,175,100,131,100C87.3,100,44,100,22,100L0,100Z"></path>
                    </svg>
                
                </div>
            </div>
            <section className="relative md:py-24 py-16">
                <div className="md:container md:mx-auto max-sm:px-4">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-12 md:col-span-12">
                            <div id="eq_faq" className="">
                                <h2 className="text-4xl font-semibold mb-6">Event Organizer Frequently Asked Questions</h2>
                                <div className="divide-y divide-gray-200 border-gray-200 dark:divide-gray-700 dark:border-gray-700 rounded-lg border" data-testid="flowbite-accordion">
                                  {ShowhomeFaq()} 

                                </div>
                            </div>
                        <div>
                        </div>
                        <div id="tb_faq" className="mt-8">
                            <h2 className="text-4xl font-semibold mb-6">Ticket Buyer Frequently Asked Questions </h2>            
                            <div className="divide-y divide-gray-200 border-gray-200 dark:divide-gray-700 dark:border-gray-700 rounded-lg border" data-testid="flowbite-accordion">
                                {ShowhomeFaq1()}
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="md:container md:mx-auto md:mt-24 mt-16">
                    <div className="grid grid-cols-1 text-center">
                    <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Have Question ? Get in touch!</h3>
                    {ShowhomeFaq}
                    <p className="text-slate-400 max-w-xl mx-auto">If you haven't found the answers to your questions here then please ask as at <NavLink to={'/contact-us'}>Contact us</NavLink> or use the Help and Chat icons at the bottom right of each page.</p>
                    <div className="mt-6"> <NavLink to={'/contact-us'} className="btn bg-brand-600 hover:bg-brand-700 border-brand-600 hover:border-brand-700 text-white rounded-md ltr:mr-2 rtl:ml-2 mt-2"><i className="uil uil-phone"></i> Contact us</NavLink> </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
    return(
        <>
        
        {ShowfaqContents()}
        </>
    )
   
}
export default Faq;
