import { useState } from "react";
import Stepper from "./eventcomponents/Stepper";
import StepperControl from "./eventcomponents/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";

import Eventname from "./eventcomponents/steps/Eventname";
import Details from "./eventcomponents/steps/Details";
import Payment from "./eventcomponents/steps/Payment";
import Final from "./eventcomponents/steps/Final";

export default function Eventform() {
  const [currentStep, setCurrentStep] = useState(1);

  const steps = [
    "Event Create",
    "Personal Details",
    "Payment",
    "Complete",
  ];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <Eventname cstep={handleClick} />;
      case 2:
        return <Details />;
      case 3:
        return <Payment />;
      case 4:
        return <Final />;
      default:
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;
    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  return (
    <>
        {}
        <div className="mx-auto rounded-2xl bg-white pb-2 shadow-xl">
          {/* Stepper */}
          <div className="horizontal container mt-5 ">
              <Stepper steps={steps} currentStep={currentStep} />

              <div className="my-10 p-10 ">
              <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
              </div>
          </div>

          {/* navigation button */}
          {currentStep !== steps.length && (
              <StepperControl
              handleClick={handleClick}
              currentStep={currentStep}
              steps={steps}
              />
          )}
        </div>
    </>
  );
}
