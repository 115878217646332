import axios from "axios";
import {
    ExclamationCircleIcon,PlusSmallIcon
  } from '@heroicons/react/24/outline'
import React, {useState, useEffect} from "react"
import { Checkbox, Table,Pagination,Modal,Button } from 'flowbite-react';
import { NavLink,useNavigate } from "react-router-dom";
const BASE_URL = "https://application.coconuttickets.com";

export default function Accordiontable() {
    const history = useNavigate();
    const user_token = localStorage.getItem("coconuttoken");// Replace with your Bearer token
    var rows = '';
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user_token}`
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [contentid,setContentid] = useState();
    const [refreshKey, setRefreshKey] = useState(0);
    const itemsPerPage = 10;
    const [totalPage, settotalPage] = useState(1);
    const [openModal, setOpenModal] = useState();
    const props = { openModal, setOpenModal };
    const Show_modal= (e) =>{
        props.setOpenModal('pop-up');
        //console.log(e.target.getAttribute("data-content"))
        setContentid(e.target.getAttribute("data-content"))
    }
    const ShowPosts = () => {
         
        const [posts, setPosts] = useState([]);
        useEffect( () => { 
            async function fetchData() {
                axios.get(`${BASE_URL}/api/faqs?page=`+currentPage, { headers })
                .then(response => {
                    console.log(response)
                    rows = response.data.data.data;
                    setPosts(rows);
                    
                    settotalPage(Math.ceil(response.data.data.total/itemsPerPage));
                })
                .catch(error => {
                    console.error(error);
                })
    
            }
            fetchData();
        }, [currentPage,refreshKey]);
        const map1 = posts.map((row, i) => {
            return (
                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="p-4">
                        <Checkbox />
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {row.title} 
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {row.page }
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {row.section}
                    </Table.Cell>
                    <Table.Cell>
                    <NavLink to={"/edit_faq/"+row.id}  key={"/edit_faq?"+row.id}>Edit </NavLink> | <span To="/" className="text-red-700" data-content={row.id} onClick={Show_modal}>Delete</span>
                    </Table.Cell>
                </Table.Row>
            );
        })
        return <Table.Body className="divide-y">
                {map1}
                </Table.Body>
        
    }
    const Deletefaqs = (event) => {
        event.preventDefault();
        axios
        .delete( BASE_URL +'/api/faqs/'+contentid,{ headers }) // Replace 
        .then((response) => {
            props.setOpenModal(undefined)
            setRefreshKey(contentid)
        })
        .catch((error) => {
          console.error(error);
        });
    }
    
    return (
       <>
       
        <NavLink to="/add_faq"  key="/add_faq"  className="flex flex-row-reverse pb-6">
            <Button Display Name size="xs" className="bg-lime-500">
                <PlusSmallIcon className="h-6 w-6 text-white" />
                <p> Add new Section </p>
            </Button>
        </NavLink> 
        <Table hoverable striped>
            <Table.Head>
                <Table.HeadCell className="p-4">
                <Checkbox />
                </Table.HeadCell>
                <Table.HeadCell>
                Content Title
                </Table.HeadCell>
                <Table.HeadCell>
                Page
                </Table.HeadCell>
                <Table.HeadCell>
                Section
                </Table.HeadCell>
                <Table.HeadCell>
                Action
                </Table.HeadCell>
                <Table.HeadCell>
                <span className="sr-only">
                    Edit
                </span>
                </Table.HeadCell>
            </Table.Head>
            {ShowPosts()}
        </Table>
        <Pagination
            currentPage={currentPage}
            onPageChange={page=>{
                setCurrentPage(page)
            }}
            showIcons
            totalPages={totalPage}
        />
        <Modal show={props.openModal === 'pop-up'} size="md" popup onClose={() => props.setOpenModal(undefined)}>
            <Modal.Header />
            <Modal.Body>
            <div className="text-center">
                <ExclamationCircleIcon className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to delete this?
                </h3>
                <div className="flex justify-center gap-4">
                <Button color="failure" onClick={Deletefaqs}>
                    Yes, I'm sure
                </Button>
                <Button color="gray" onClick={() => props.setOpenModal(undefined)}>
                    No, cancel
                </Button>
                </div>
            </div>
            </Modal.Body>
        </Modal>
       </>
                
    )
}



