import axios from "axios";
import React, { useState } from 'react';
import  Dashboardnav  from '../Dashboardnav.js';
import { TextInput, Button,Textarea, Label } from 'flowbite-react';
import { useNavigate } from "react-router-dom";


const BASE_URL = "https://application.coconuttickets.com";

const FormComponent = () => {
    const history = useNavigate();
    const user_token = localStorage.getItem("coconuttoken");// Replace with your Bearer token
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${user_token}`
    };
    
  // State to store form data
  const [formData, setFormData] = useState({
    title: '',
    page: '',
    section: '',
    content: '',
    button_text: '',
    button_url: '',
  });

  // State to handle success or error notifications

  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform any validation here if needed
    axios
    .post( BASE_URL +'/api/faqs',formData,{ headers }) // Replace 
    .then((response) => {
        history('/accordion_settings');
    })
    .catch((error) => {
      // Handle the error response
      console.error(error);
    });
    
  };

  return (
    <>
        <div className='flex flex-nowrap'>
            <aside id="default-sidebar" className="z-40 w-64 transition-transform -translate-x-full sm:translate-x-0" >
                <Dashboardnav/>
            </aside>
            <div className="p-4 admin-container">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                    <form  className="flex flex-col gap-4" onSubmit={handleSubmit}>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="title"
                                    value="Title"
                                />
                            </div>
                                <TextInput
                                id="title"
                                placeholder="Title"
                                name="title"
                                onChange={handleInputChange}
                                value={formData.title}
                                required
                                shadow
                                type="text"
                                />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="page"
                                    value="Page"
                                />
                            </div>
                                <TextInput
                                id="page"
                                placeholder="Targeted page"
                                name="page"
                                onChange={handleInputChange}
                                value={formData.page}
                                required
                                shadow
                                type="text"
                                />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="section"
                                    value="Section"
                                />
                            </div>
                                <TextInput
                                id="section"
                                placeholder="Targeted section"
                                name="section"
                                onChange={handleInputChange}
                                value={formData.section}
                                required
                                shadow
                                type="text"
                                />
                        </div>
                        <div
                            id="textarea"
                            >
                            <div className="mb-2 block">
                                <Label
                                htmlFor="section_content"
                                value="Content"
                                />
                            </div>
                            <Textarea
                                id="section_content"
                                placeholder="Right your content here..."
                                name="content"
                                onChange={handleInputChange}
                                value={formData.section_content}
                                required
                                rows={4}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="button_text"
                                    value="Button Test"
                                />
                            </div>
                                <TextInput
                                id="button_text"
                                placeholder="Targeted Button Test"
                                name="button_text"
                                onChange={handleInputChange}
                                value={formData.button_text}
                                shadow
                                type="text"
                                />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="button_url"
                                    value="Button url"
                                />
                            </div>
                                <TextInput
                                id="button_url"
                                placeholder="Targeted Button url"
                                name="button_url"
                                onChange={handleInputChange}
                                value={formData.button_url}
                                shadow
                                type="url"
                                />
                        </div>
                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <Button type="submit">Submit</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
  );
};

export default FormComponent;
