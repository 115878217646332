import React from "react";
import Reviewtable from "./Reviewtable";
import  Dashboardnav  from '../Dashboardnav.js';

export default function Review_crud() {
     
  return (
    <>
        {}
        <div className='flex flex-nowrap'>
            <aside id="default-sidebar" className="z-40 w-64 transition-transform -translate-x-full sm:translate-x-0" >
                <Dashboardnav/>
            </aside>

            <div className="p-4 admin-container">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                  <Reviewtable />
                </div>
            </div>

        </div>

    </>
    )
}

