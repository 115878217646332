import { Outlet, Navigate } from "react-router-dom";
import useAuth from "../auth/Authservice.js";
import React, { useCallback, useEffect, useState } from "react";
export default function PrivateRoute() {
  const [auth, setAuth] = useState("loading");
  const authchecker =useAuth();
  const checkAuth = useCallback(() => {
    if(authchecker){
      setAuth("success");
     }else{
      setAuth("fail");
     }
  }, []);
  
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  const renderPrivate = () => {
    if (auth === "loading") {
      return (
        <div>
          <h3>Loading</h3>
        </div>
      );
    } else if (auth === "success") {
      return <Outlet />;
    } else {
      return <Navigate to="/login" />;
    }
  };

  return renderPrivate();
}
