import axios from "axios";
import React, { useEffect, useState } from 'react';
import  Dashboardnav  from '../Dashboardnav.js';
import { TextInput, Button,Textarea,FileInput, Label,Radio } from 'flowbite-react';
import { useNavigate } from "react-router-dom";


const BASE_URL = "https://application.coconuttickets.com";

const FormComponent = () => {
    const [images, setImages] = useState([]);
    const [imageURLs, setImageURLs] = useState([]);
    const history = useNavigate();
    const user_token = localStorage.getItem("coconuttoken");// Replace with your Bearer token
    
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user_token}`
    };
    
    // State to store form data
    const [formData, setFormData] = useState({
        title: '',
        sub_title: '',
        page: '',
        section: '',
        review: '',
        content: '',
        button_text: '',
        button_url: '',
        thumnail_url: '',
    });
    // Handle form input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    
    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        axios
        .post( BASE_URL +'/api/reviews',formData,{ headers }) // Replace 
        .then((response) => {
            history('/review_settings');
        })
        .catch((error) => {
            console.error(error);
        });
    };
    useEffect(()=>{
        if(images.length < 1) return;
        let formData = new FormData();
        formData.append('image', images[0]);
       
        axios({
            method: "post",
            url: "https://application.coconuttickets.com/api/image",
            data: formData,
            headers: { "Content-Type": "multipart/form-data",'Authorization': `Bearer ${user_token}` },
          })
            .then(function (response) {
              setImageURLs(BASE_URL + response.data.image);
             
                // setFormData({
                //     ...formData,
                //     ['thumnail_url']: BASE_URL + response.data.image,
                // });
            })
            .catch(function (response) {
              console.log(response);
            });
    },[images])
    function onImageChange(e){
        setImages([...e.target.files])
    }
    
    return (
        <>
        <div className='flex flex-nowrap'>
            <aside id="default-sidebar" className="z-40 w-64 transition-transform -translate-x-full sm:translate-x-0" >
            <Dashboardnav/>
            </aside>
            <div className="p-4 admin-container">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                    <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                        <div>
                        <div className="mb-2 block">
                        <Label
                        htmlFor="title"
                        value="Title"
                        />
                        </div>
                        <TextInput
                            id="title"
                            placeholder="Title"
                            name="title"
                            onChange={handleInputChange}
                            value={formData.title}
                            required
                            shadow
                            type="text"
                            />
                        </div>
                        <div>
                        <div className="mb-2 block">
                            <Label
                            htmlFor="sub_title"
                            value="Sub Title"
                            />
                            </div>
                            <TextInput
                            id="sub_title"
                            name="sub_title"
                            placeholder="Sub Title"
                            onChange={handleInputChange}
                            value={formData.sub_title}
                            shadow
                            type="text"
                            />
                            </div>
                        
                        <div>
                        <div>
                            <fieldset
                                className="flex max-w-md flex-row gap-4"
                                id="radio"
                                >
                                <legend className="mb-4">
                                    Choose your Rating
                                </legend>
                                <div className="flex items-center gap-2">
                                    <Radio
                                    defaultChecked
                                    id="one_star"
                                    name="review"
                                    value="1"
                                    onChange={handleInputChange}
                                    />
                                    <Label htmlFor="one_star">
                                        1
                                    </Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Radio
                                    id="two_star"
                                    name="review"
                                    value="2"
                                    onChange={handleInputChange}
                                    />
                                    <Label htmlFor="two_star">
                                    2
                                    </Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Radio
                                    id="three_star"
                                    name="review"
                                    onChange={handleInputChange}
                                    value="3"
                                    />
                                    <Label htmlFor="three_star">
                                    3
                                    </Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Radio
                                    id="four_star"
                                    name="review"
                                    onChange={handleInputChange}
                                    value="4"
                                    />
                                    <Label htmlFor="four_star">
                                        4
                                    </Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Radio
                                    id="five_star"
                                    name="review"
                                    onChange={handleInputChange}
                                    value="5"
                                    />
                                    <Label
                                    htmlFor="five_star"
                                    >
                                        5
                                    </Label>
                                </div>
                            </fieldset>
                        </div>
                        <div className="mb-2 block">
                            <Label
                            htmlFor="page"
                            value="Page"
                            />
                            </div>
                            <TextInput
                            id="page"
                            placeholder="Targeted page"
                            name="page"
                            onChange={handleInputChange}
                            value={formData.page}
                            required
                            shadow
                            type="text"
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                htmlFor="section"
                                value="Section"
                                />
                            </div>
                            <TextInput
                                id="section"
                                placeholder="Targeted section"
                                name="section"
                                onChange={handleInputChange}
                                value={formData.section}
                                required
                                shadow
                                type="text"
                                />
                        </div>
                        <div id="textarea">
                            <div className="mb-2 block">
                                <Label
                                htmlFor="section_content"
                                value="Content"
                                />
                            </div>
                            <Textarea
                                id="section_content"
                                placeholder="Right your content here..."
                                name="content"
                                onChange={handleInputChange}
                                value={formData.section_content}
                                required
                                rows={4}
                                />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                htmlFor="button_text"
                                value="Button Test"
                                />
                            </div>
                            <TextInput
                                id="button_text"
                                placeholder="Targeted Button Test"
                                name="button_text"
                                onChange={handleInputChange}
                                value={formData.button_text}
                                shadow
                                type="text"
                                />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                htmlFor="button_url"
                                value="Button url"
                                />
                            </div>
                            <TextInput
                                id="button_url"
                                placeholder="Targeted Button url"
                                name="button_url"
                                onChange={handleInputChange}
                                value={formData.button_url}
                                shadow
                                type="url"
                                />
                        </div>
                        <div> 
                            <div className="mb-2 block">
                                <Label
                                htmlFor="thumnail_url"
                                value="Thumnail URL"
                                />
                            </div>
                            <TextInput
                                id="thumnail_url"
                                placeholder="Targeted thumnail URL"
                                name="thumnail_url"
                                onChange={handleInputChange}
                                value={imageURLs}
                                shadow
                                type="text"
                                />
                        </div>
                        <div className="max-w-md" id="fileUpload">
                            <div className="mb-2 block">
                                <Label
                                htmlFor="file"
                                value="Upload file"
                                />
                            </div>
                            <FileInput
                            helperText="Upload Carousel Image here"
                            id="file"
                            onChange={onImageChange} 
                            />
                        </div>
                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <Button type="submit">Submit</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </>
        );
    };
    
    export default FormComponent;
    