import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { NavLink,Link } from "react-router-dom";

import {
  Bars3Icon,
  ListBulletIcon,
  XMarkIcon,
  TicketIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const solutions = [
  {
    name: 'Buy Tickets',
    to: '/event',
    icon: TicketIcon,
  },
  {
    name: 'Buy Vendor Pitches',
    to: '/pitches',
    icon: ListBulletIcon,
    
  }
]
const resources = [
  {
    name: 'Help Center',
    to: '#',
  },
  {
    name: 'Guides',
    to: '#',
  },
]
const routes_link = [
  {
    name: 'Pricing',
    href: 'https://app.coconuttickets.com/pricing',
  },
  {
    name: 'About',
    to: '/about',
  },
  {
    name: 'Blog',
    to: '/blog',
  },
  {
    name: 'FAQ',
    to: '/faq',
  },
  {
    name: 'Buy ticket',
    href: 'https://app.coconuttickets.com/events'
  },
  {
    name: 'Contact us',
    to: '/contact-us',
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Nav() {
  return (
    <Popover className="relative bg-white">
      <div className="mx-auto max-w-12xl px-4 sm:px-6">
        <div className="flex items-center justify-between border-b-2 border-gray-100 py-4 md:justify-start md:space-x-10 md:container md:mx-auto">
          <div className="flex justify-center lg:w-0 lg:flex-1">
            <NavLink to={'/'}>
              <span className="sr-only">Coconut tickets</span>
              <img
                className="h-8 w-auto sm:h-10"
                src={process.env.PUBLIC_URL + 'images/logo-dark.webp'}
                alt="Coconut tickets"
              />
            </NavLink>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500 font-semibold">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex place-content-baseline font-semibold mt-4">
          {routes_link.map((item) => (
            (
              item.href ?
          (<a  href={item.href} className="sub-menu-item uppercase hover:text-brand-600">
          {item.name}</a>)
            :
            (<NavLink to={item.to} key={item.name} href={item.href} className="sub-menu-item uppercase hover:text-brand-600">
              {item.name}
              
            </NavLink>)
           
            )))
          }
          </Popover.Group>
          <div className="hidden items-center justify-center md:flex md:flex-1 lg:w-0 mt-4">
            <a href="https://app.coconuttickets.com/login" className="ml-2 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-brand-600 bg-white-600 px-4 py-2 text-base font-medium text-brand-600 shadow-sm hover:bg-brand-600 hover:bg-neutral-700 hover:border-neutral-700">
              Login
            </a>
            <a
             href="https://app.coconuttickets.com/sign-up"
              className="ml-2 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-brand-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-neutral-700 hover:border-neutral-700"
            >
              Sign up
            </a>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden z-50">
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <NavLink to={'/'}>
                    <span className="sr-only">Coconut tickets</span>
                    <img
                      className="h-8 w-auto sm:h-10"
                      src={process.env.PUBLIC_URL + 'images/logo-dark.webp'}
                      alt="Coconut tickets"
                    />
                  </NavLink>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-900 focus:outline-none">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="space-y-6 py-6 px-5">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
              {routes_link.map((item) => (
                (
                  item.href ?
                (<a  href={item.href} className="sub-menu-item uppercase hover:text-brand-600">
                {item.name}</a>)
                  :
                  (<NavLink to={item.to} key={item.name} href={item.href} className="sub-menu-item uppercase hover:text-brand-600">
                    {item.name}
                    
                  </NavLink>)
                
                  )))
              }
              </div>
              <div>
                <a
                href='https://app.coconuttickets.com/sign-up'
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-brand-600  px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-dark-700"
                >
                  Sign up
                </a>
                <p className="mt-6 text-center text-base font-medium text-gray-900">
                  Existing customer?{' '}
                  <a href='https://app.coconuttickets.com/login' className="text-brand-600 hover:text-dark-500">
                    Sign in
                  </a>
                </p>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
export default Nav;