import React from 'react';
import  Dashboardnav  from './Dashboardnav.js';
import  Eventform  from "./Eventwizard/Eventform";
import MultiStepForm from './Multistep/MultiStepForm';
export default function Eventwizard() {
    
    return (
    <>
        {}
        <div className='flex flex-nowrap'>
            <aside id="default-sidebar" className="z-40 w-64 transition-transform -translate-x-full sm:translate-x-0" >
                <Dashboardnav/>
            </aside>

            <div className="p-4 admin-container">
                <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
                    <Eventform />
                    <MultiStepForm />
                </div>
            </div>

        </div>
    </>
    )
}