import React from 'react';
import  Dashboardnav  from './Dashboardnav.js';
export default function Dashboard() {
    
    return (
    <>
        {}
        <Dashboardnav/>
    </>
    )
}