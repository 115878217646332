import React, { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { CheckIcon,TicketIcon,CalendarDaysIcon,BanknotesIcon }  from '@heroicons/react/24/outline'
import {  Button,Progress } from 'flowbite-react';
const steps = [Step1, Step2, Step3];
const stepsIcon = [<TicketIcon/>, <CalendarDaysIcon/>, <BanknotesIcon/>];
const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    field1: '',
    field2: '',
    field3: '',
  });
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };
  
  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };
  
  const CurrentStepComponent = steps[currentStep];
  const progress = ((currentStep + 1) / steps.length) * 100;
  
  return (
    <div>
      <div className="progress-bar">
        <Progress progress={progress}/>
      </div>
      <div className="step-icons">
        {steps.map((_, index) => (
          <div key={index} className={`step-icon ${currentStep === index ? 'active' : ''}`}>
            {currentStep > index ? <CheckIcon className="h-4 w-4" /> : stepsIcon[index]}
          </div>
        ))}
      </div>
      <CurrentStepComponent data={formData} handleChange={handleChange} />
      <div className="button-container">
        {/* <div className="inline-flex rounded-md shadow-sm" role="group">
          {currentStep > 0 && <Button onClick={handlePrevious} color="info">Previous</Button>}
          {currentStep < steps.length - 1 && <Button onClick={handleNext} color="info">Next</Button>}
          {currentStep === steps.length - 1 && <Button color="info">Submit</Button>}
        </div> */}
        <div className="inline-flex rounded-md shadow-sm" role="group">
        {currentStep > 0 && <button type="button"  onClick={handlePrevious} color="info" className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
        Previous
            </button>}
            {currentStep < steps.length - 1 &&<button onClick={handleNext} color="info" type="button" className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
            Next
            </button>}
            {currentStep === steps.length - 1 && <button type="button" color="info" className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
            Submit
            </button>}
          </div>
      </div>
    </div>
  );
};

export default MultiStepForm;