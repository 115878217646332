import axios from "axios";
import {Navigate} from "react-router-dom";
//import { useMutation } from 'react-query';
const baseURL = "https://application.coconuttickets.com/api";
export function dologin(email,password) {
   
  const logins = async () => {
    const { data: response } = await axios.post(baseURL+'/login',{
        email: email,
        password: password
      },
      {
        'headers': {'content-type': 'multipart/form-data'}
      }
    ).then(result => {
      return result;
    }).catch(err => {
      return err.response;
    });
    return response;
  };
  return logins;
};
export default function useAuth(){
  const auth =  localStorage.getItem("iscoconutLoggedIn");
  return auth;
}

export function cocotlogout(){

  localStorage.removeItem('coconuttoken');
  localStorage.removeItem("iscoconutLoggedIn");
  <Navigate to="/login" replace />;
  window.location.reload(false);
};
// export function login({ useremail, password }) {
 
//     const loginStatus =  fetch(`${baseUrl}/login/`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ useremail, password })
//     })
//     return loginStatus;
// }
