import {
    TagIcon,CalendarDaysIcon,IdentificationIcon,HomeIcon,LifebuoyIcon,ArrowRightOnRectangleIcon,EnvelopeIcon,Cog8ToothIcon
  } from '@heroicons/react/24/outline'
import React,{useState} from 'react';
import {Sidebar} from 'flowbite-react';
import { cocotlogout } from '../auth/Authservice.js';
import { NavLink } from "react-router-dom";

  const navigation = [
    { 
      name: 'Dashboard',
      icon: HomeIcon, 
      submenu: [{name: 'Event Dashboard',to: '/dashboard3' },{name: 'Event Group Definition',to: '/group-wizard' },{name: 'Analytics',to: '/ct-sales?control=analytics' },{name: 'Sales Revenue',to: '/data-maintenance?control=sales_activity' },{name: '#Tickets sold',to: '/data-maintenance?control=tickets_sold' },{name: '#Tickets remaining',to: '/data-maintenance?control=ts_tickets_sold' },{name: '#Tickets sold by coupon',to: '/data-maintenance?control=tickets_sold_by_coupon'},{name: '#Time Slot Tickets sold',to: '/data-maintenance?control=tickets_remaining'}]
    },{ 
      name: 'Booking',
      icon: CalendarDaysIcon, 
      submenu: [{name: 'Manage Bookings',to: '/manage_bookings' },{name: 'Manage Order',to: '/manage_orders' },{name: 'Manage bids',to: '/manage_bids' },{name: 'Manually accept orders',to: '/manual_acceptance' },{name: 'Manage Manual Payments',to: '/manage_manual_payments' },{name: 'New Booking',to: '/ops_booking' },{name: 'Vendor Image Gallery',to: '/vendor_gallery'}]
    },{ 
      name: 'Sales',
      icon: TagIcon, 
      submenu: [{name: 'Create Event',to:'/event-wizard'},
      {name: 'Coupons',to:'/ct-sales?control=coupon_definition'},
      {name: 'Door List',to:'/data-maintenance?control=door_list'},
      {name: 'Time Slot Door List',to: '/data-maintenance?control=ts_door_list'},
      {name: 'Customer Database',to: '/data-maintenance?control=customer_database'},
      {name: 'Customer Event Comparison',to: '/data-maintenance?control=customer_comparison'},
      {name: 'Customer Revenue',to: '/data-maintenance?control=customer_revenue'},
      {name: 'Wait List Requests',to: '/data-maintenance?control=wait_list_requests' }]
    },{
      name: 'Email',
      icon: EnvelopeIcon, 
      submenu: [{name: 'Sent email',to:'/ct_email_archive'}]
    },{
      name: 'Profile',
      icon: IdentificationIcon,
      submenu:[{
        name: 'Business Profile', 
        to: '/profile'
        },{
            name: 'Scanner app settings',  
            to: '/ct-sales?control=scan_settings'  
        },{
            name: 'On sale history',  
            to: '/data-maintenance?control=ct_on_sale_status'
        },{
            name: 'Manage subscription',  
            to: '/subscription'
        },{
            name: 'New payment card',  
            to: '/subscription/new-card'
        },{         
            name: 'Change password',  
            to: '/password/change'
        }
      ]
    },{
      name: 'Help',
      icon: LifebuoyIcon,
      submenu:[ {
        name: 'Community articles & tutorials',
        to: '/coconut-tickets-community-help-center'
      },
      {
        name:  'Helpdesk',
        to:  '/Helpdesk_support_requests'
      }
      ]
    }
  ]
  const adminnavigation = [
    { 
      name: 'Settings',
      icon: Cog8ToothIcon,
      submenu: [{name: 'Section content',to:'/section_contents'},{name: 'Accordion settings',to:'/accordion_settings'},{name: 'carosel settings',to:'/review_settings'}]
    }
  ];
  export default function Dashboardnav() {
    const [collapsed, setcollapsed] = useState(false)
    return (
    <>
        {}
        <div className="w-fit bg-gray-700 text-white-600/100 relative" >
            <button className="absolute top-1.5 right-0" onClick={(event) => {event.preventDefault();setcollapsed(!collapsed)}}>
            {
              collapsed 
              ? 
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
             :                   
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
           
              }
            </button>
            <Sidebar collapsed={collapsed} aria-label="Sidebar with multi-level dropdown example" className='bg-stone-700 min-nav-w'>
                <Sidebar.Items>
                <Sidebar.ItemGroup>
                    {navigation.map((item) => (
                        <Sidebar.Collapse
                        icon={item.icon}
                        label={item.name}
                        >
                          {item.submenu.map((subitem) =>(
                               <NavLink to={subitem.to} key={subitem.name} className="text-base font-medium text-gray-500 hover:text-gray-900">
                                <Sidebar.Item >
                                  {subitem.name}
                                </Sidebar.Item>
                              </NavLink>

                          ))}
                        </Sidebar.Collapse>
                        
                        ))
                      }
                       {adminnavigation.map((item) => (
                        <Sidebar.Collapse
                        icon={item.icon}
                        label={item.name}
                        >
                          {item.submenu.map((subitem) =>(
                               <NavLink to={subitem.to} key={subitem.name} className="text-base font-medium text-gray-500 hover:text-gray-900">
                                <Sidebar.Item >
                                  {subitem.name}
                                </Sidebar.Item>
                              </NavLink>

                          ))}
                        </Sidebar.Collapse>
                        
                        ))
                      }
                      <Sidebar.Item
                        icon={ArrowRightOnRectangleIcon}
                        onClick={() => {cocotlogout()}}
                      >
                        Sign out
                    </Sidebar.Item>
                </Sidebar.ItemGroup>
                </Sidebar.Items>
            </Sidebar>
       
        </div>
    </>
    )
  }
  