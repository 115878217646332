
import { LockClosedIcon } from '@heroicons/react/20/solid'
import React, { useState } from 'react';
import toast, { Toaster } from "react-hot-toast";
import { useMutation } from 'react-query';
import { useNavigate } from "react-router-dom";
import { dologin } from './Authservice.js';

function Login() {

  const history = useNavigate();
  const [email, setUseremail] = useState("");
  const [password, setPassword] = useState("");
  const submitlogin = () => {
    mutation.mutate(dologin);
  }
  const mutation  = useMutation(dologin(email,password), {
      onSuccess: data => {
        if(typeof(data) != 'undefined' ){
          if(data.message === 'Logged in successfully'){
            if( (typeof(data.token) != 'undefined') && (typeof(data.token) != null)  ){
              localStorage.setItem("iscoconutLoggedIn", true);
              localStorage.setItem("coconuttoken", data.token);
              toast.success(data.message,{
                duration: 2000,
                position: 'top-right'});
               // console.log(client)
              setTimeout(() => {
                history('/dashboard');
                window.location.reload(false);
              }, 200);
            }
              
          }else{
            toast.error(data.message,{
              duration: 4000,
              position: 'top-right'});
          }
        }
      },
      onError: data => {
        
     
      }
   });
   
  
  return (
    <>
  
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt="Your Company"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign in to your account
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Or{' '}
              <div className="font-medium text-indigo-600 hover:text-indigo-500">
                start your 14-day free trial
              </div>
            </p>
          </div>
          <form className="mt-8 space-y-6" method="POST"  
            onSubmit={ (e) => {
              e.preventDefault();
              submitlogin();
              
            }}
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={(e) =>  setUseremail(e.target.value)}
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <div className="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                
                   // disabled={!useremail || !password}
                  //  loading={mutation.isLoading}
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
                Sign in
              </button>
            </div>
          </form>
          <Toaster />
        </div>
      </div>
    </>
  )
}
export default Login;
