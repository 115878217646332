// Step1.js
import React from 'react';
import { TextInput, Button } from 'flowbite-react';

const Step2 = ({ data, handleChange }) => {
  return (
    <div>
      <TextInput label="Field 1" name="field1" value={data.field1} onChange={handleChange} />
    </div>
  );
};

export default Step2;